import {  FaRegUser } from "react-icons/fa";
import { PiShoppingCartBold } from "react-icons/pi";
import { TbGiftCard, TbPackageExport, TbTruckDelivery } from "react-icons/tb";
import { IoBagHandle, IoWalletOutline } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
// import FashionList from "../components/FashionList";
import BrandList from "../components/BrandList";
import { BiRupee } from "react-icons/bi";
import {
  MdLocationPin,
  MdOutlineCelebration,
  MdOutlineEditLocation,
  MdOutlineSettings,
  MdPayment,
} from "react-icons/md";
// import { SiRazorpay } from "react-icons/si";
import { BsCash } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";
import { LiaCertificateSolid } from "react-icons/lia";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaXTwitter,
} from "react-icons/fa6";

import googlePay from "../assets/images/google-pay.png";
import amazonPay from "../assets/images/amazon-pay.png";
import payTM from "../assets/images/paytm-icon.png";
import phonePe from "../assets/images/phonepe.png";
import payU from "../assets/images/payu-icon.png";
import razorPay from "../assets/images/razorpay-icon.png";
import masterCard from "../assets/images/money.png";
import cash_on_delivery from "../assets/images/cash-on-delivery.png";

const navLinks = [
  { title: "BRANDS", content: <BrandList /> },
  { title: "UTSAV", url: "/utsav", content: <></> },
  // { title: "FASHION", url: "/fashion", content: <></> },
  // { title: "STUDIO", content: <></> },
];

const footerLinks = {
  main: [
    { title: "about us", url: "/" },
    { title: "who are we?", url: "/" },
    { title: "track order", url: "/" },
    { title: "careers", url: "/" },
  ],
  help: [
    { title: "contact us", url: "/contact-us" },
    { title: "shipping & delivery", url: "/shipping-delivery-policy" },
    { title: "cancel & return", url: "/return-refund-policy" },
    { title: "store location", url: "/" },
  ],
  "quick links": [
    { title: "bath rug", url: "/search?query=bath rug" },
    { title: "door mats", url: "/search?query=door mats" },
    { title: "hand towel", url: "/search?query=hand towel" },
  ],
  "top picks": [
    { title: "home decor", url: "/search?query=home decor" },
    { title: "nail care", url: "/search?query=nail care" },
    { title: "electronics", url: "/search?query=electronics" },
    { title: "hair care", url: "/search?query=hair care" },
  ],
};

const navTexts = [
  "FREE SHIPPING UNDER ₹299",
  "free shipping under ₹299",
  "free shipping under ₹299",
];

const accountTabs = [
  { title: "my profile", url: "profile", icon: <FaRegUser /> },
  { title: "orders", url: "orders", icon: <PiShoppingCartBold /> },
  { title: "gift cards", url: "gift-card", icon: <TbGiftCard /> },
  { title: "utsav", url: "become-utsav", icon: <MdOutlineCelebration /> },
  { title: "my wallet", url: "wallet", icon: <IoWalletOutline /> },
  { title: "manage address", url: "address", icon: <MdOutlineEditLocation /> },
  { title: "refer & earn", icon: <GiPayMoney /> },
  { title: "settings", url: "settings", icon: <MdOutlineSettings /> },
  { title: "logout", icon: <IoMdLogOut /> },
];

const isPasswordValid = (password) => {
  if (!password)
    return {
      success: false,
      message: "Password is required",
    };

  const isValid = PASSWORD_REGEX.test(password);
  if (!isValid)
    return {
      success: false,
      message:
        "Your password must be have at least 8 characters long, 1 uppercase & 1 lowercase character, 1 number",
    };

  return {
    success: true,
    message: "OK",
  };
};

const changePasswordFields = [
  {
    label: "old password",
    type: "password",
    name: "oldPassword",
    validation: isPasswordValid,
  },
  {
    label: "new password",
    type: "password",
    name: "newPassword",
    validation: isPasswordValid,
  },
  {
    label: "confirm password",
    type: "password",
    name: "confirmNewPassword",
    validation: isPasswordValid,
  },
];

const checkoutSteps = [
  { name: "cart", icon: <IoBagHandle /> },
  { name: "address", icon: <MdLocationPin /> },
  { name: "payment", icon: <BiRupee /> },
];

const paymentDetails = [
  // { label: "Razorpay", method: "Razorpay", icon: <SiRazorpay /> },
  { label: "Card / Net Banking / UPI", method: "PayU", icon: <MdPayment /> },
  { label: "Cash on Delivery", method: "COD", icon: <BsCash /> },
];

// Regular Expressions for Validations
const NAME_REGEX = /^[a-zA-Z-\s]+$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
const PASSWORD_REGEX =
  /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
const PIN_CODE_REGEX = /^[1-9][0-9]{5}$/;

// Order Status
const orderStatus = [
  "All Orders",
  "Pending",
  "Confirmed",
  "Shipping",
  "Out For delivery",
  "Delivered",
  "Returned",
  "Canceled",
  "Completed",
];

const serverOrderStatus = [
  "Pending",
  "Confirmed",
  "Shipping",
  "Out For delivery",
  "Delivered",
  "Returned",
  "Canceled",
  "Completed",
];

const getColorForStatus = (status) => {
  switch (status) {
    case "Pending":
      return "bg-yellow-400";
    case "Confirmed":
      return "bg-green-400";
    case "Shipping":
      return "bg-blue-300";
    case "Out For delivery":
      return "bg-cyan-500";
    case "Delivered":
      return "bg-lime-500";
    case "Returned":
      return "bg-orange-400";
    case "Canceled":
      return "bg-red-500";
    case "Completed":
      return "bg-green-500";
    default:
      return "bg-gray-200";
  }
};

const TRENDING_SEARCHES = [
  "t-shirt for men",
  "apple watch series 9",
  "white sneakers for men",
  "home decor items for wall",
  "face massager for glowing skin",
];

const PLATFORM_FEATURES = [
  {
    icon: <TbTruckDelivery />,
    title: "free shipping",
    description: "On orders above ₹ 499",
  },
  {
    icon: <TbPackageExport />,
    title: "easy returns",
    description: "14-Days Return Policy",
  },
  {
    icon: <LiaCertificateSolid />,
    title: "100% authentic",
    description: "Secured products",
  },
];

const PLATFORM_SOCIALS = [
  { icon: <FaXTwitter />, accent: "#14171a", url: "https://x.com/Finafid_" },
  {
    icon: <FaFacebookF />,
    accent: "#1877f2",
    url: "https://www.facebook.com/finafidofficial/",
  },
  {
    icon: <FaInstagram />,
    accent: "#c13584",
    url: "https://www.instagram.com/finafidofficial/profilecard/?igsh=MWI1dmUzdGdyd20zZQ%3D%3D",
  },
  { icon: <FaPinterest />, accent: "#e60023", url: "/" },
  {
    icon: <FaLinkedin />,
    accent: "#0a66c2",
    url: "https://www.linkedin.com/company/finafid",
  }
];

const PLATFORM_PAYMENT_METHODS = [
  googlePay,
  amazonPay,
  phonePe,
  payU,
  masterCard,
  payTM,
  razorPay,
  cash_on_delivery,
];

export {
  navLinks,
  footerLinks,
  navTexts,
  accountTabs,
  changePasswordFields,
  checkoutSteps,
  paymentDetails,
  NAME_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PASSWORD_REGEX,
  PIN_CODE_REGEX,
  orderStatus,
  serverOrderStatus,
  getColorForStatus,
  TRENDING_SEARCHES,
  PLATFORM_FEATURES,
  PLATFORM_SOCIALS,
  PLATFORM_PAYMENT_METHODS,
};
